body {
    background-color: white;
    color: black;
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: #222;
        color: white;
    }
}

body {
    &>div[data-page] {
        display: none;
    }
}

body[data-page=docs] {
    &>div[data-page=docs] {
        display: block;
    }
}

body[data-page=edit] {
    &>div[data-page=edit] {
        display: block;
    }
}

a {
    text-decoration: none;
}

#pageDocs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    padding: 25px;

    &>a {
        display: block;
        background-size: cover;
        width: 90svw;
        max-width: 400px;
        height: 90svw;
        max-height: 400px;
        padding: 10px;

        border: 1px solid black;
        color: black;

        @media (prefers-color-scheme: dark) {
            border: 1px solid #ccc;
            color: white;
        }

        &:hover {
            background-color: rgba(127, 127, 127, 0.5);
        }
    }
}

#pageEdit {
    touch-action: none;
    user-select: none;

    width: 100svw;
    height: 100svh;
    // https://stackoverflow.com/a/33168168/2398020
    overflow: hidden;
    position: fixed;

    #gesture-area {
        position: absolute;
        width: 100svw;
        height: 100svh;

        img {
            position: absolute;

            // Always use pixel width.
            max-width: none;
            width: auto;

            transform-origin: 0 0;

            &#img-ref {
                border: 0.2svw solid grey;
            }

            &[src="data:,"] {
                display: none;
            }
        }
    }

    .control {
        position: absolute;
        display: flex;
        flex-wrap: wrap; // Temp
        text-align: center;

        &>* {
            flex-grow: 0.1;
        }

        &>.grow {
            flex-grow: 1;
        }

        input {
            vertical-align: middle;
        }

        input[type="file"] {
            display: none;
        }

        label,
        button,
        a {
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;

            background-color: rgba(255, 255, 255, 0.5);
            color: black;
            border: 1px solid black;

            @media (prefers-color-scheme: dark) {
                background-color: rgba(0, 0, 0, 0.5);
                color: white;
                border: 1px solid #ccc;
            }
        }

        label:has(>input[type=checkbox]:checked) {
            background-color: rgba(127, 127, 127, 0.5);
        }

        &.hidden :not(#button-hide) {
            display: none;
        }
    }
}