body {
  color: #000;
  background-color: #fff;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: #222;
  }
}

body > div[data-page] {
  display: none;
}

body[data-page="docs"] > div[data-page="docs"], body[data-page="edit"] > div[data-page="edit"] {
  display: block;
}

a {
  text-decoration: none;
}

#pageDocs {
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  padding: 25px;
  display: flex;
}

#pageDocs > a {
  color: #000;
  background-size: cover;
  border: 1px solid #000;
  width: 90svw;
  max-width: 400px;
  height: 90svw;
  max-height: 400px;
  padding: 10px;
  display: block;
}

@media (prefers-color-scheme: dark) {
  #pageDocs > a {
    color: #fff;
    border: 1px solid #ccc;
  }
}

#pageDocs > a:hover {
  background-color: #7f7f7f80;
}

#pageEdit {
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100svw;
  height: 100svh;
  position: fixed;
  overflow: hidden;
}

#pageEdit #gesture-area {
  width: 100svw;
  height: 100svh;
  position: absolute;
}

#pageEdit #gesture-area img {
  transform-origin: 0 0;
  width: auto;
  max-width: none;
  position: absolute;
}

#pageEdit #gesture-area img#img-ref {
  border: .2svw solid gray;
}

#pageEdit #gesture-area img[src="data:,"] {
  display: none;
}

#pageEdit .control {
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  position: absolute;
}

#pageEdit .control > * {
  flex-grow: .1;
}

#pageEdit .control > .grow {
  flex-grow: 1;
}

#pageEdit .control input {
  vertical-align: middle;
}

#pageEdit .control input[type="file"] {
  display: none;
}

#pageEdit .control label, #pageEdit .control button, #pageEdit .control a {
  cursor: pointer;
  color: #000;
  background-color: #ffffff80;
  border: 1px solid #000;
  padding: 6px 12px;
  display: inline-block;
}

@media (prefers-color-scheme: dark) {
  #pageEdit .control label, #pageEdit .control button, #pageEdit .control a {
    color: #fff;
    background-color: #00000080;
    border: 1px solid #ccc;
  }
}

#pageEdit .control label:has( > input[type="checkbox"]:checked) {
  background-color: #7f7f7f80;
}

#pageEdit .control.hidden :not(#button-hide) {
  display: none;
}
/*# sourceMappingURL=index.1e0f4a28.css.map */
